// extracted by mini-css-extract-plugin
export const footerWrapper = "Footer-module--footerWrapper--1xoUO";
export const topFooter = "Footer-module--topFooter--2Rox7";
export const bottomFooter = "Footer-module--bottomFooter--2OuAV";
export const container = "Footer-module--container--1ScUQ";
export const legalDocumentationContainer = "Footer-module--legalDocumentationContainer--2IaGk";
export const socialMediaContainer = "Footer-module--socialMediaContainer--3DBdg";
export const socialMediaLink = "Footer-module--socialMediaLink--uTqed";
export const copyrightContainer = "Footer-module--copyrightContainer--21Src";
export const downloadContainer = "Footer-module--downloadContainer--3NcT0";
export const appLink = "Footer-module--appLink--2W-mW";
export const button = "Footer-module--button--1d-Zv";