import React from 'react';
import logo from '../images/logoLightVersion.svg';

import * as styles from './Header.module.scss';

const Header = (): React.ReactElement => {
  return (
    <header className={styles.header}>
      <div className={styles.container}>
        <a href="/" className="logoContainer">
          <img src={logo} alt=" " />
        </a>
        {/* <a
          target="_blank"
          rel="noreferrer"
          href="https://app.vivavoz.pt/"
          className="button lightButton"
        >
          Entrar
        </a> */}
      </div>
    </header>
  );
};

export default Header;
