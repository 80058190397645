import React from 'react';
import Header from './Header';
import Footer from './Footer';
import * as styles from './Layout.module.scss';

interface LayoutProps {
  children: React.ReactNode;
}

const Layout = ({ children }: LayoutProps): React.ReactElement => {
  return (
    <div className={styles.pageWrapper}>
      <Header></Header>
      <main className={styles.mainPage}>{children}</main>
      <Footer></Footer>
    </div>
  );
};

export default Layout;
