import React from 'react';
import Helmet from 'react-helmet';

interface SEOProps {
  title: string;
  description: string;
  url: string;
  imageUrl: string;
  lang: string;
}

function SEO({ title, description, url, imageUrl, lang }: SEOProps): React.ReactElement {
  return (
    <Helmet>
      <html lang={lang} />
      <title>{title}</title>
      <meta property="og:type" content="website"></meta>
      <meta name="twitter:card" content="summary"></meta>
      {title && <meta property="og:title" content={title}></meta>}
      {title && <meta name="twitter:title" content={title}></meta>}
      {description && <meta name="description" content={description}></meta>}
      {description && <meta property="og:description" content={description}></meta>}
      {description && <meta name="twitter:description" content={description}></meta>}
      {url && <meta property="og:url" content={url}></meta>}
      {imageUrl && <meta property="og:image" content={imageUrl}></meta>}
      <meta name="author" content="Mosca Digital"></meta>
      <meta name="twitter:creator" content="Mosca Digital"></meta>
    </Helmet>
  );
}

export default SEO;
