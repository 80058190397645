import React from 'react';

export const GenericLinkSerializer = (props: {
  mark: {
    url: string;
  };
  children: React.ReactNode;
}): React.ReactElement => {
  return <a href={props.mark.url}>{props.children}</a>;
};

export const EmbeddedImageSerializer = (props: {
  node: {
    image: {
      asset: {
        originalFilename: string;
        url: string;
      };
    };
    imageWidth: number;
  };
}): React.ReactElement => {
  return (
    // <div className={styles.embbededImageWrapper}>
    <div>
      <img
        src={props.node.image.asset.url + ('?w=' + (props.node.imageWidth || '930') + '&fit=max')}
        alt={props.node.image.asset.originalFilename}
      />
    </div>
  );
};
