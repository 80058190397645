import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { FaFacebook, FaInstagram, FaTwitter } from 'react-icons/fa';
import logo from '../images/logoLightVersion.svg';
import googlePlay from '../images/googlePlay.svg';
import appStore from '../images/appStore.png';
import * as styles from './Footer.module.scss';

const Footer = (): React.ReactElement => {
  const data = useStaticQuery(graphql`
    {
      sanityTerms {
        _rawTermsDescription(resolveReferences: { maxDepth: 4 })
        seo {
          ...SEOFragment
        }
      }
      sanityCookiePolicy {
        _rawCookiePolicyDescription(resolveReferences: { maxDepth: 4 })
        seo {
          ...SEOFragment
        }
      }
      sanityPrivacyPolicy {
        _rawPrivacyPolicyDescription(resolveReferences: { maxDepth: 4 })
        seo {
          ...SEOFragment
        }
      }
      sanityAboutUs {
        _rawAboutUsDescription(resolveReferences: { maxDepth: 4 })
        seo {
          ...SEOFragment
        }
      }
    }
  `);
  return (
    <footer className={styles.footerWrapper}>
      <div className={styles.topFooter}>
        <div className={styles.container}>
          <a className="logoContainer" href="/">
            <img src={logo} alt="" />
          </a>
          <div className={styles.legalDocumentationContainer}>
            <div>
              {!!data.sanityAboutUs._rawAboutUsDescription && <a href="/sobre-nos">Sobre Nós</a>}

              {!!data.sanityTerms._rawTermsDescription && (
                <a href="/termos">Termos de Utilização</a>
              )}
            </div>
            <div>
              {!!data.sanityCookiePolicy._rawCookiePolicyDescription && (
                <a href="/politica-de-cookies">Política de Cookies</a>
              )}
              {!!data.sanityPrivacyPolicy._rawPrivacyPolicyDescription && (
                <a href="/politica-de-privacidade">Política de Privacidade</a>
              )}
            </div>
          </div>
          <div className={styles.socialMediaContainer}>
            {/* <a className={styles.socialMediaLink} target="_blank" rel="noreferrer" href="#">
              <FaFacebook className={styles.icon}></FaFacebook>
            </a> */}
            <a
              className={styles.socialMediaLink}
              target="_blank"
              rel="noreferrer"
              href="https://www.instagram.com/vivavoz.pt/"
            >
              <FaInstagram className={styles.icon}></FaInstagram>
            </a>
            <a
              className={styles.socialMediaLink}
              target="_blank"
              rel="noreferrer"
              href="https://twitter.com/VivaVozpt"
            >
              <FaTwitter className={styles.icon}></FaTwitter>
            </a>
          </div>
        </div>
      </div>

      <div className={styles.bottomFooter}>
        <div className={styles.container}>
          <div className={styles.copyrightContainer}>
            <span>{new Date().getFullYear()} © | Direitos reservados Alfabeto Delicado</span>
            <span>
              Powered by{' '}
              <a target="_blank" rel="noreferrer" href={'https://moscadigital.pt/?utm_source=vivavoz.pt'}>
                Mosca Digital
              </a>
            </span>
          </div>
          {/* <div className={styles.downloadContainer}>
            <a
              href="https://play.google.com/store/apps/details?id=pt.vivavoz"
              target="_blank"
              rel="noreferrer"
              className={styles.appLink + ' logoContainer'}
            >
              <img src={googlePlay} alt="" />
            </a>
            <a
              href="https://apps.apple.com/pt/app/pt.vivavoz/id1579394431"
              target="_blank"
              rel="noreferrer"
              className={styles.appLink + ' logoContainer'}
            >
              <img src={appStore} alt="" />
            </a>
            <a
              href="https://app.vivavoz.pt/"
              target="_blank"
              rel="noreferrer"
              className={styles.button + ' button lightButton'}
            >
              Abrir na Web
            </a>
          </div> */}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
